import { SVGProps } from 'react';

const UserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={'100%'}
    height={'100%'}
    viewBox={'0 0 16 16'}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.333 14v-1.333A2.667 2.667 0 0 0 10.666 10H5.333a2.667 2.667 0 0 0-2.667 2.667V14M8 7.333A2.667 2.667 0 1 0 8 2a2.667 2.667 0 0 0 0 5.333Z'
      stroke='#ADB5BD'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default UserIcon;
