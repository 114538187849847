import { SVGProps } from 'react';

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M21 15.438v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M7 10.438l5 5 5-5M12 15.438v-12'
        stroke='#F62'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DownloadIcon;
