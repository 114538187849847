import { SVGProps } from 'react';

const CoopDollarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.159 18.125a4.652 4.652 0 1 0 0-9.303 4.652 4.652 0 0 0 0 9.303Z'
        stroke='#212529'
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d='M15.287 6A4.659 4.659 0 0 0 7.5 9.451a4.653 4.653 0 0 0 4.659 4.652 4.647 4.647 0 0 0 3.835-2.017M12.25 2v19'
        stroke='#212529'
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
};

export default CoopDollarIcon;
