import { SVGProps } from 'react';

const EyeforceServiceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={'100%'}
    height={'100%'}
    viewBox='0 0 56 57'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M44.333 7.004H11.667A4.667 4.667 0 0 0 7 11.67v32.666a4.667 4.667 0 0 0 4.667 4.667h32.666A4.667 4.667 0 0 0 49 44.337V11.671a4.667 4.667 0 0 0-4.667-4.667ZM14 14.004l28 28M42 14.004l-28 28'
      stroke='#1C75BC'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx={28} cy={28.004} r={14} stroke='#1C75BC' strokeWidth={4} />
  </svg>
);

export default EyeforceServiceIcon;
