import { SVGProps } from 'react';

const ArticleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill='none'
      data-testid='article-icon'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2V3ZM22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7V3Z'
        stroke='#6C757D'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ArticleIcon;
