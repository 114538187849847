import { SVGProps } from 'react';

const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={'100%'}
    height={'100%'}
    viewBox={'0 0 16 16'}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g
      clipPath='url(#a)'
      stroke='#ADB5BD'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z' />
      <path d='M12.933 10a1.1 1.1 0 0 0 .22 1.213l.04.04a1.334 1.334 0 1 1-1.886 1.887l-.04-.04a1.1 1.1 0 0 0-1.214-.22 1.1 1.1 0 0 0-.667 1.007V14a1.333 1.333 0 0 1-2.666 0v-.06A1.1 1.1 0 0 0 6 12.933a1.1 1.1 0 0 0-1.213.22l-.04.04A1.333 1.333 0 0 1 2.57 11.74c.068-.161.166-.308.29-.432l.04-.04a1.1 1.1 0 0 0 .22-1.214 1.1 1.1 0 0 0-1.007-.666H2A1.333 1.333 0 0 1 2 6.72h.06A1.1 1.1 0 0 0 3.066 6a1.1 1.1 0 0 0-.22-1.213l-.04-.04A1.333 1.333 0 1 1 4.694 2.86l.04.04a1.1 1.1 0 0 0 1.214.22H6a1.1 1.1 0 0 0 .667-1.007V2a1.333 1.333 0 0 1 2.666 0v.06A1.1 1.1 0 0 0 10 3.067a1.1 1.1 0 0 0 1.213-.22l.04-.04a1.333 1.333 0 1 1 1.887 1.886l-.04.04a1.1 1.1 0 0 0-.22 1.214V6a1.1 1.1 0 0 0 1.006.667H14a1.333 1.333 0 1 1 0 2.666h-.06a1.1 1.1 0 0 0-1.007.667v0Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h16v16H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default SettingsIcon;
