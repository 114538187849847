import { SVGProps } from 'react';

const LeftArrowIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox={'0 0 24 24'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='leftarrow-icon'
      {...props}
    >
      <path
        d='M19 12H5M12 19l-7-7 7-7'
        stroke='#6C757D'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default LeftArrowIcon;
