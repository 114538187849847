import { SVGProps } from 'react';

const VideoUrlIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15.026 4.28a1.853 1.853 0 0 0-1.293-1.334C12.586 2.667 8 2.667 8 2.667s-4.587 0-5.734.307A1.853 1.853 0 0 0 .973 4.306a19.333 19.333 0 0 0-.307 3.527c-.007 1.192.096 2.381.307 3.553a1.854 1.854 0 0 0 1.293 1.28c1.147.307 5.734.307 5.734.307s4.586 0 5.733-.307a1.853 1.853 0 0 0 1.293-1.333 19.34 19.34 0 0 0 .307-3.5 19.34 19.34 0 0 0-.307-3.553v0Z'
        stroke='#6C757D'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='m6.5 10.013 3.833-2.18L6.5 5.653v4.36Z'
        stroke='#6C757D'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default VideoUrlIcon;
