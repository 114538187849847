import { SVGProps } from 'react';

const HomeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      fill='none'
      viewBox='0 0 24 24'
      data-testid='home-icon'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='m2.602 9 9-7 9 7v11a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2V9Z'
        stroke='#6C757D'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 22V12h6v10'
        stroke='#6C757D'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default HomeIcon;
