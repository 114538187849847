import { SVGProps } from 'react';

const CoopIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={25}
      height={24}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='coop-icon'
      {...props}
    >
      <path
        d='M12.5 11c5.523 0 10-2.015 10-4.5S18.023 2 12.5 2s-10 2.015-10 4.5 4.477 4.5 10 4.5ZM22.5 11.5c0 2.485-4.477 4.5-10 4.5s-10-2.015-10-4.5M22.5 17.5c0 2.485-4.477 4.5-10 4.5s-10-2.015-10-4.5'
        stroke='#ADB5BD'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CoopIcon;
