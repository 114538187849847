import { SVGProps } from 'react';

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={14}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.67 2.17h-1.5v-.84c0-.27-.23-.5-.5-.5s-.5.23-.5.5v.84H4.83v-.84c0-.27-.22-.5-.5-.5s-.5.23-.5.5v.84h-1.5C1.32 2.17.5 2.99.5 4v9.33c0 1.01.82 1.84 1.83 1.84h9.34c1.01 0 1.83-.83 1.83-1.84V4c0-1.01-.82-1.83-1.83-1.83Zm.83 11.16c0 .46-.37.84-.83.84H2.33c-.46 0-.83-.38-.83-.84V7.17h11v6.16Zm0-7.16h-11V4c0-.46.37-.83.83-.83h1.5V4c0 .28.23.5.5.5s.5-.22.5-.5v-.83h4.34V4c0 .28.22.5.5.5s.5-.22.5-.5v-.83h1.5c.46 0 .83.37.83.83v2.17Z'
        fill='#ADB5BD'
      />
    </svg>
  );
};

export default CalendarIcon;
