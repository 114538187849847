import { SVGProps } from 'react';

const GlobeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8 .83C4.08.83.89 4 .84 7.9v.2c.04 3.87 3.19 7.02 7.06 7.06.03.01.06.01.1.01.04 0 .07 0 .1-.01 3.87-.04 7.02-3.19 7.06-7.06v-.2C15.11 4 11.92.83 8 .83Zm2.14 6.67H5.86C6 5.53 6.75 3.64 8 2.1a9.6 9.6 0 0 1 2.14 5.4ZM6.87 1.94A10.68 10.68 0 0 0 4.85 7.5h-3a6.185 6.185 0 0 1 5.02-5.56ZM1.85 8.5h3c.14 2.01.84 3.94 2.02 5.56-2.7-.5-4.79-2.77-5.02-5.56ZM8 13.9a9.6 9.6 0 0 1-2.14-5.4h4.28A9.671 9.671 0 0 1 8 13.9Zm1.13.16a10.68 10.68 0 0 0 2.02-5.56h3a6.185 6.185 0 0 1-5.02 5.56Zm2.02-6.56c-.14-2.01-.84-3.94-2.01-5.56 2.7.5 4.78 2.77 5.01 5.56h-3Z'
        fill='#ADB5BD'
      />
    </svg>
  );
};

export default GlobeIcon;
