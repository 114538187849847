import styled from 'styled-components';

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

type Color = RGB | RGBA | HEX;

interface IconWrapperProps {
  color?: Color;
  size: number;
  thick?: number;
}

export const IconWrapper = styled.div<IconWrapperProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  & > svg path {
    stroke: ${(props) => (props.color ? props.color : null)};
    stroke-width: ${(props) => props.thick};
  }

  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
`;
