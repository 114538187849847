import { SVGProps } from 'react';

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={'100%'}
    height={'100%'}
    viewBox={'0 0 16 16'}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6 14H3.333A1.334 1.334 0 0 1 2 12.667V3.333A1.333 1.333 0 0 1 3.333 2H6M10.666 11.333 14 8l-3.334-3.333M14 8H6'
      stroke='#ADB5BD'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default LogoutIcon;
