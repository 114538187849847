import { SVGProps } from 'react';

const MarketingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='marketing-icon'
    {...props}
  >
    <path
      d='M10 3H3v7h7V3ZM22.28 5.219l-6.061-3.5-3.5 6.062 6.062 3.5 3.5-6.062ZM21 14h-7v7h7v-7ZM10 14H3v7h7v-7Z'
      stroke='#6C757D'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default MarketingIcon;
