import { SVGProps } from 'react';

const GlassIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox='0 0 24 24'
      width={'100%'}
      height={'100%'}
      fill='none'
      data-testid='glass-icon'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11 19a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM21 21l-4.35-4.35'
        stroke='#6C757D'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default GlassIcon;
