import { SVGProps } from 'react';

const EyeforceIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox={'0 0 24 25'}
      fill='none'
      data-testid='eyeforce-icon'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='m8.796 10.062-2.611 9.69a9.975 9.975 0 0 1-3.679-8.124l6.29-1.566ZM16.023 21.36A9.994 9.994 0 0 1 12.5 22a9.94 9.94 0 0 1-5.015-1.346l1.661-6.17 6.877 6.877ZM12.166 7.687 2.69 10.046A10.018 10.018 0 0 1 7.7 3.223l4.466 4.464ZM16.075 9.487 9.159 2.574A9.901 9.901 0 0 1 12.5 2c1.904 0 3.685.532 5.2 1.457l-1.625 6.03ZM13.165 16.394l9.108-2.264a10.033 10.033 0 0 1-4.813 6.56l-4.295-4.296Z'
        stroke='#ADB5BD'
        strokeWidth={1.5}
        strokeLinejoin='round'
      />
      <path
        d='M22.5 12.002c0 .181 0 .36-.013.538l-10.435 2.595L14 13.5l3.333-2.958h.003l1.654-6.148a9.976 9.976 0 0 1 3.51 7.607Z'
        stroke='#ADB5BD'
        strokeWidth={1.5}
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EyeforceIcon;
