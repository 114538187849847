import { SVGProps } from 'react';

const DealerPortalHostedCodeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#a)' stroke='#6C757D' strokeLinejoin='round'>
        <path d='m5.53 6.708-1.74 6.46a6.65 6.65 0 0 1-2.453-5.416L5.53 6.708ZM10.349 14.24A6.663 6.663 0 0 1 8 14.668a6.627 6.627 0 0 1-3.343-.898l1.107-4.113 4.585 4.585ZM7.777 5.125 1.461 6.697A6.678 6.678 0 0 1 4.8 2.15l2.977 2.976ZM10.383 6.325l-4.61-4.609A6.601 6.601 0 0 1 8 1.333c1.269 0 2.456.355 3.466.972l-1.083 4.02ZM8.443 10.93l6.072-1.51a6.69 6.69 0 0 1-3.209 4.373L8.443 10.93Z' />
        <path d='M14.666 8c0 .122 0 .24-.008.36L7.7 10.09 9 9l2.222-1.972h.002l1.102-4.099a6.651 6.651 0 0 1 2.34 5.072Z' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DealerPortalHostedCodeIcon;
