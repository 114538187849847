import styled from 'styled-components';

export const Header1 = styled.h1`
  margin: 0;
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
`;

export const Header2 = styled.h2`
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

export const Header3 = styled.h3`
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
`;

export const Header4 = styled.h4`
  margin: 0;
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

export const Header5 = styled.h5`
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`;

export const Header6 = styled.h6`
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const BodyText1 = styled.p`
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const BodyText2 = styled.p`
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export const BodyText3 = styled.p`
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const SubTitle = styled.label`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: 'PT Sans';
  font-style: normal;
  margin: 0;
`;

export const Display1 = styled.h1`
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  line-height: 100px;
`;

export const Display6 = styled.h1`
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
`;
