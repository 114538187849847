import { SVGProps } from 'react';

const DangerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={'100%'}
    height={'100%'}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0v0ZM12 9v4M12 17h.01'
      stroke='#DC3545'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default DangerIcon;
