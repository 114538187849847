import { SVGProps } from 'react';

const EyeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      data-testid='eye-icon'
      {...props}
    >
      <path
        d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8Z'
        // stroke="#6C757D"
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
        // stroke="#6C757D"
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EyeIcon;
