import { SVGProps } from 'react';

const AddressIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 1.167a5.5 5.5 0 0 0-5.5 5.5c0 2.132 1.383 4.177 2.863 5.74A19.796 19.796 0 0 0 8 14.721a19.794 19.794 0 0 0 2.637-2.316C12.117 10.845 13.5 8.8 13.5 6.667a5.5 5.5 0 0 0-5.5-5.5Zm0 14.167c-.277.416-.277.416-.278.415h-.001l-.004-.003-.015-.01a11.083 11.083 0 0 1-.253-.178 20.783 20.783 0 0 1-2.812-2.464C3.117 11.49 1.5 9.201 1.5 6.667a6.5 6.5 0 1 1 13 0c0 2.534-1.617 4.823-3.137 6.427a20.786 20.786 0 0 1-3.01 2.605l-.055.037-.015.01-.004.002v.001c-.001 0-.002 0-.279-.415Zm0 0 .278.415a.502.502 0 0 1-.556 0L8 15.335Z'
        fill='#ADB5BD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 5.167a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-2.5 1.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z'
        fill='#ADB5BD'
      />
    </svg>
  );
};

export default AddressIcon;
