import { SVGProps } from 'react';

const SdmIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='sdm-icon'
    {...props}
  >
    <g clipPath='url(#a)'>
      <g
        clipPath='url(#b)'
        stroke='#ADB5BD'
        strokeWidth={1.5}
        strokeLinejoin='round'
      >
        <path d='M7.5 21.359 10.653 2.64H14.7l.418 12.075L19.383 2.64h4.363L20.735 21.36h-3.256l2.16-12.626-4.097 12.626h-3.126l.141-12.626-2.116 12.626H7.5Z' />
        <path d='M10.804 2.641H4.712L1.5 21.36h6.152' />
        <path
          d='M8 6.989C8 6.444 7.648 6 7.22 6H6.5c-.216 0-.408.111-.552.288-.14.181-.228.423-.228.696 0 .54.352.983.78.983l.5.066c.428 0 .78.444.78.983 0 .54-.352.984-.78.984h-.72c-.428 0-.78-.444-.78-.984M4 17.96 4.62 14l.705.01c.774.16 1.29 1.024 1.153 1.923l-.112.694c-.138.899-.749 1.453-1.876 1.364L4 17.96ZM4.9 12h2.5'
          strokeLinecap='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' transform='translate(.5)' d='M0 0h24v24H0z' />
      </clipPath>
      <clipPath id='b'>
        <path fill='#fff' transform='translate(.5 2)' d='M0 0h24v20H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default SdmIcon;
